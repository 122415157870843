const DataLayerWrapper = ({ children }) => {
    if (window.dataLayer !== undefined)
        window.dataLayer.push({
            event: 'view_content',
            platform_type: 'blog',
        })

    return children
}

export default DataLayerWrapper
