import React from "react";
import ReactGA from "react-ga4";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import HomePage from "../pages";
import SinglePost from "../pages/single-post";
import LatestPost from "../pages/latest-post";

// const TRACKING_ID = "G-MJJR003YD3";
const TRACKING_ID = "UA-57933422-33";
ReactGA.initialize(TRACKING_ID);

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/:id" element={<SinglePost />} />
      <Route exact path="/latest" element={<LatestPost />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
