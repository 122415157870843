import axios from "axios";

export const fetchBlogs = async (category) => {
  return await axios.get(`/beforeafters?categories=${category}`);
};

export const fetchSingleBlogPost = async (id) => {
  return await axios.get(`/beforeafters/${id}`);
};

export const fetchLatestBlogPost = async (category) => {
  return await axios.get(`/beforeafters/latest?categories=${category}`);
};
