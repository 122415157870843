import React from "react";
import DataLayerWrap from './components/DataLayerWrap';
import Router from "./router";

const App = () => (
  <DataLayerWrap>
    <Router />
  </DataLayerWrap>
);

export default App;
