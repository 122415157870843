import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { fetchBlogs } from "../api/blog";

import "../styles/blog.css";

const HomePage = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationPages, setPaginationPages] = useState(0);

  const [searchParam] = useSearchParams();

  const getQueryParam = (key) => searchParam.get(key);

  const getPageNumber = () => Math.ceil(blogs.length / 4);

  useEffect(() => {
    const fetchData = async () => {
      const category = getQueryParam("categories");
      const { data } = await fetchBlogs(category);

      const modifiedArray = data.beforeAfters.map((item) => ({
        ...item,
        sliderValue: 50,
      }));

      setBlogs(modifiedArray);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setPaginationPages(getPageNumber());
  }, [blogs]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="container2" className="blog container">
      {blogs.slice(page * 4, (page + 1) * 4).map((blog) => (
        <div className="avant-section" key={blog.id}>
          <div className="avant-section-left">
            <img style={{ width: "50%" }} src={blog.afterImage} />
            <img
              id="my-img"
              style={{
                width: "50%",
                clipPath:
                  "polygon(0 0," +
                  blog.sliderValue +
                  "% 0," +
                  blog.sliderValue +
                  "% 100%, 0 100%)",
              }}
              src={blog.beforeImage}
            />
            <input
              type="range"
              min="0"
              max="100"
              value={blog.sliderValue}
              id="slider"
              className="slide2"
              onChange={(event) => {
                const updatedBlogs = blogs.map((b) => {
                  if (b.id === blog.id) {
                    b.sliderValue = Number(event.target.value);
                  }
                  return b;
                });

                setBlogs(updatedBlogs);
              }}
            />
          </div>
          <div className="avant-section-right">
            <Link to={`/${blog.id}`}>
              <h2>{blog.beforeAfterTitle}</h2>
            </Link>
            <p>{blog.beforeAfterDescription}</p>
          </div>
        </div>
      ))}
      <div className="pagination">
        <div
          className="hover-pagination"
          onClick={() => page !== 0 && setPage(page - 1)}
        >
          &laquo;
        </div>
        {[...Array(paginationPages)].map((a, i) => (
          <div className="hover-pagination" onClick={() => setPage(i)} key={i}>
            {i + 1}
          </div>
        ))}
        <div
          className="hover-pagination"
          onClick={() =>
            page < paginationPages - 1 ? setPage(page + 1) : undefined
          }
        >
          &raquo;
        </div>
      </div>
    </div>
  );
};

export default HomePage;
