import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";


import { fetchBlogs } from "../api/blog";

import "../styles/single-post.css";
import "../styles/latest.css";

const Blog = () => {
  const [blog, setBlog] = useState();
  const [sliderValue, setSliderValue] = useState(50);

  const [searchParam] = useSearchParams();

  const getQueryParam = (key) => searchParam.get(key);

  useEffect(() => {
    const fetchData = async () => {
      const category = getQueryParam("categories");
      const { data } = await fetchBlogs(category);

      const modifiedBlog = {
        ...data.beforeAfters[0],
        sliderValue: 50,
      };

      setBlog(modifiedBlog);
    };

    fetchData();
  }, []);



  return (
    <div className="single-post container">
      {blog && (
        <div className="single-post container">
          <h2><div className="post-title" id="latest-post-title">
            {blog.beforeAfterTitle}
          </div></h2>
          <p className="mt-4">{blog.beforeAfterDescription}</p>
          <div className="avant-section">
            <img
              className="comparingImages"
              style={{
                width: "100%",
                objectFit: "cover",
              }}
              src={blog.afterImage}
            />
            <img
              id="my-img"
              className="comparingImages"
              style={{
                width: "100%",
                objectFit: "cover",
                clipPath:
                  "polygon(0 0," +
                  sliderValue +
                  "% 0," +
                  sliderValue +
                  "% 100%, 0 100%)",
              }}
              src={blog.beforeImage}
            />
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              id="slider2"
              className="slide2"
              onChange={(event) => setSliderValue(event.target.value)}
            />
          </div>
         
        
        </div>
      )}
    </div>
  );
};

export default Blog;
