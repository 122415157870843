import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

import { fetchSingleBlogPost } from "../api/blog";

import "../styles/single-post.css";

const Blog = () => {
  const [blog, setBlog] = useState();
  const [sliderValue, setSliderValue] = useState(50);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchSingleBlogPost(id);

      setBlog(data?.beforeAfter);
    };

    fetchData();
  }, []);

  const formatDate = (date) => moment(date).format("DD/MM/YYYY");

  // const avant = `${window.location.origin.toString()}/avant-apres/`;

  return (
    <div className="single-post container">
      {blog && (
        <div className="single-post container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                Accueil
              </li>
              <li className="breadcrumb-item">
                Avant-Après
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {blog.beforeAfterTitle}
              </li>
            </ol>
          </nav>
          <h1 className="post-title">{blog.beforeAfterTitle}</h1>
          <p>
            <FontAwesomeIcon icon={faClock} id="clockIcon" />
            Mis à jour le {formatDate(blog.date.split("T")[0])}
          </p>
          <div className="avant-section">
            <img
              className="comparingImages"
              style={{
                width: "100%",
                objectFit: "cover",
              }}
              src={blog.afterImage}
            />
            <img
              id="my-img"
              className="comparingImages"
              style={{
                width: "100%",
                objectFit: "cover",
                clipPath:
                  "polygon(0 0," +
                  sliderValue +
                  "% 0," +
                  sliderValue +
                  "% 100%, 0 100%)",
              }}
              src={blog.beforeImage}
            />
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              id="slider2"
              className="slide2"
              onChange={(event) => setSliderValue(event.target.value)}
            />
          </div>
          <p className="mt-4">{blog.beforeAfterDescription}</p>
          <h2 className="mt-5">{blog.beforeTitle}</h2>
          <p>{blog.beforeDescription}</p>
          <img className="single-image" src={blog.beforeImage} />
          <br></br>
          <h2 className="mt-5">{blog.afterTitle}</h2>
          <p>{blog.afterDescription}</p>
          <img className="single-image" src={blog.afterImage} />
        </div>
      )}
    </div>
  );
};

export default Blog;
